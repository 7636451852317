const DEV = process.env.NODE_ENV === 'development'

export default {
  init() {
    var elements = document.querySelectorAll('.js-counter-number')

    elements.forEach(function (item) {
      item.counterAlreadyFired = false
      item.counterSpeed = 100
      item.counterTarget = +item.innerText
      item.counterStep = 1

      const cookieName = item.getAttribute('data-name')
      if (getCookie(cookieName)) {
        if (getCookie(cookieName) == item.counterTarget) {
          item.counterCount = 0
        } else {
          item.counterCount = getCookie(cookieName)
          setCookie(cookieName, item.counterTarget, 365)
        }
      } else {
        item.counterCount = 0
        setCookie(cookieName, item.counterTarget, 365)
      }
      item.innerText = item.counterCount

      item.updateCounter = function () {
        item.counterCount = item.counterCount + item.counterStep
        item.innerText = Math.ceil(item.counterCount)

        if (item.counterCount < item.counterTarget) {
          setTimeout(item.updateCounter, item.counterSpeed)
        } else {
          item.innerText = item.counterTarget
        }
      }
    })

    var isElementVisible = function isElementVisible(el) {
      var rect = el.getBoundingClientRect()
      return (
        rect.top >= 0 &&
        rect.bottom + 20 <=
          (window.innerHeight || document.documentElement.clientHeight)
      )
    }

    var handleScroll = function handleScroll() {
      elements.forEach(function (item, id) {
        if (true === item.counterAlreadyFired) return
        if (!isElementVisible(item)) return
        item.updateCounter()
        item.counterAlreadyFired = true
      })
    }

    window.addEventListener('scroll', handleScroll)

    function setCookie(cname, cvalue, exdays) {
      const d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      let expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    }

    function deleteCookie(cname) {
      document.cookie =
        cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
    }

    function getCookie(cname) {
      let name = cname + '='
      let ca = document.cookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    }

    function checkCookie() {
      let user = getCookie('username')
      if (user != '') {
        alert('Welcome again ' + user)
      } else {
        user = prompt('Please enter your name:', '')
        if (user != '' && user != null) {
          setCookie('username', user, 365)
        }
      }
    }
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
}
