const DEV = process.env.NODE_ENV === 'development'
import { gsap, set } from 'gsap'

export default {
  init() {
    const countryTooltip = document.getElementById('countryTooltip')
    const buttons = document.getElementsByClassName('change-chart-btn')

    const enableButtons = () => {
      Array.from(buttons).forEach((btn) => {
        btn.disabled = false
      })
    }

    const disableButtons = () => {
      Array.from(buttons).forEach((btn) => {
        btn.disabled = true
      })
    }

    const hideCountryTooltip = () => {
      gsap.to(countryTooltip, {
        autoAlpha: 0,
        duration: 0.3,
        overwrite: true,
      })
    }
    const hideCountryTooltipImmediatly = () => {
      countryTooltip.style.opacity = 0
      gsap.to(countryTooltip, {
        autoAlpha: 0,
        duration: 0,
        overwrite: true,
      })
    }
    const showCountryTooltip = () => {
      gsap.to(countryTooltip, {
        autoAlpha: 1,
        duration: 0.2,
        overwrite: true,
      })
    }

    Array.from(buttons).forEach((btn) => {
      const btnWidth = btn.getBoundingClientRect().width / 2
      btn.addEventListener('click', function (e) {
        hideCountryTooltipImmediatly()
      })

      btn.addEventListener(
        'mouseover',
        function (e) {
          countryTooltip.innerHTML = e.currentTarget.attributes.title.value
          countryTooltip.style.left =
            e.currentTarget.getBoundingClientRect().left + btnWidth + 'px'
          showCountryTooltip()
        },
        false
      )
      btn.addEventListener('mouseout', hideCountryTooltip, false)
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
}
