export default class ChartTemplateResolver {
  resolveChartTemplate(d) {
    const hideLoader = (dataWrapperId, provider) => {
      console.log('hideLoader')
      console.log('pw', provider)
      console.log('dw', dataWrapperId)
      if (provider == 'flourish') {
        //alert('flourish-')
        document
          .getElementById(`fluo-${dataWrapperId}`)
          .parentNode.classList.add('loaded')
      } else {
        document
          .getElementById(`datawrapper-vis-${dataWrapperId}`)
          .parentNode.parentNode.classList.add('loaded')
      }
    }
    // OUTER DIV
    const outerDiv = document.createElement('div')
    outerDiv.classList.add('relative', 'outer-chart')
    switch (d.width) {
      case '1/3':
        outerDiv.classList.add('lg:w-1/3')
        break
      case '1/2':
        outerDiv.classList.add('lg:w-1/2')
        break
      case '2/3':
        outerDiv.classList.add('lg:w-2/3')
        break
      case 'full':
        outerDiv.classList.add('w-full')
        break
      default:
        break
    }

    switch (d.margin) {
      case '1/3':
        outerDiv.classList.add('lg:ml-1/3')
        break
      case '1/2':
        outerDiv.classList.add('lg:ml-1/2')
        break
      case 'auto-center':
        outerDiv.classList.add('lg:mx-auto')
        break
      default:
        break
    }
    // if (d.setMaxWidth) {
    //   outerDiv.classList.add('lg:w-1/2', 'mx-auto')
    // }

    const js = document.createElement('script')
    if (d.showTitle) {
      // TITLE
      const title = document.createElement('h4')
      title.innerText = d.title
      const titleClasses = [
        'chart-title',
        'font-bold',
        'font-sans',
        'text-md',
        'text-dark',
        'mb-2',
        'relative',
      ]
      title.classList.add(...titleClasses)

      // DESCRIPTION
      const description = document.createElement('div')
      description.innerText = d.description
      const descriptionClasses = [
        'chart-subtitle',
        'font-normal',
        'font-sans',
        'text-root-m',
        'text-grey',
        'mb-4',
        'relative',
      ]
      description.classList.add(...descriptionClasses)
      outerDiv.appendChild(title)
      outerDiv.appendChild(description)
    }
    if (d.provider != 'flourish') {
      js.src = `https://datawrapper.dwcdn.net/${d.dataWrapperId}/embed.js`
      js.dataset.plain = true
      js.dataset.transparent = false
      js.onload = (e) => {
        //console.log('onload', e)
        setTimeout(hideLoader.bind(null, d.dataWrapperId, d.provider), 300)
        //   document
        //     .getElementById(`datawrapper-vis-${d.dataWrapperId}`)
        //     .parentNode.classList.add('loaded')
        //document.getElementById(`datawrapper-vis-${d.dataWrapperId}`).style = null
      }
    }

    // SPINNER DIV
    const spinner = document.createElement('div')
    let spinnerClasses = ['data-placeholder']
    if (d.provider == 'flourish') {
      spinner.dataset.src = 'visualisation/' + d.dataWrapperId
      spinner.id = 'fluo-' + d.dataWrapperId
      spinnerClasses = [
        'data-placeholder',
        'flourish-embed',
        'flourish-chart',
        //'flourish-parliament',
      ]
    }
    spinner.classList.add(...spinnerClasses)

    outerDiv.appendChild(spinner)
    spinner.appendChild(js)
    if (d.provider == 'flourish') {
      Flourish.loadEmbed(spinner)
      setTimeout(hideLoader.bind(null, d.dataWrapperId, d.provider), 300)
      //outerDiv.classList.add('loaded')
    }
    return outerDiv
  }

  resolveBoxTemplate(d) {
    return `<div></div>`
  }
}
