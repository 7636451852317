//import Notiflix from 'notiflix';
import { Notify } from 'notiflix/build/notiflix-notify-aio'

export default class SavePost {
  constructor() {
    window.addEventListener('load', () => {
      this.init()
    })
  }

  init() {
    this.elements = document.querySelectorAll('.savePost')
    this.elements.forEach((element) => {
      element.addEventListener('click', (event) => this.savePost(event))
    })
  }

  toggleStatus() {
    this.elements = document.querySelectorAll('.savePost')
    this.elements.forEach((element) => {
      element.classList.toggle('done')
    })
  }

  togglePending() {
    this.elements = document.querySelectorAll('.savePost')
    this.elements.forEach((element) => {
      element.classList.toggle('pending')
    })
  }

  savePost(event) {
    console.log(event)
    event.preventDefault()
    this.togglePending()
    const postID = event.currentTarget.dataset.postid
    const savepostnonce = event.currentTarget.dataset.savepostnonce

    window.dataLayer.push({
      event: 'save_favorite',
      subscribed: 'yes',
    })

    wp.ajax
      .post('legc_save_toggle_article_to_user_meta', {
        postID,
        savepostnonce,
      })
      .done((data) => {
        this.toggleStatus()
        this.togglePending()
        let msg =
          data.message == 'saved'
            ? lgc_global.action_messages.save_success
            : lgc_global.action_messages.save_removed
        Notify.success(msg)
      })
      .fail((error) => {
        this.togglePending()
        Notify.failure(lgc_global.action_messages.save_error)
      })
  }
}
