import MicroModal from 'micromodal'; // es6 module

export default class Login {
	constructor() {
		window.addEventListener('load', () => {
			this.init();
		});
	}

	init() {
		MicroModal.init({ disableFocus: true });
		this.loginFormElem = document.getElementById('login');
		this.loginBtn = document.getElementById('loginBtn');
		if (this.loginFormElem) {
			this.loginFormElem.addEventListener('submit', this.login);
		}
		if (document.getElementById('show_register_btn')) {
			document
				.getElementById('show_register_btn')
				.addEventListener('click', function (e) {
					e.preventDefault();
					document
						.getElementById('login-modal')
						.classList.remove('show_login');
				});
		}
	}

	login(event) {
		event.preventDefault();

		const btnText = this.loginBtn.innerHTML;
		this.loginBtn.classList.add('disabled');
		this.loginBtn.innerHTML = lgc_global.action_messages.logging_in;

		const username = event.target.elements.username.value;
		const password = event.target.elements.password.value;
		const security = event.target.elements.security.value;
		const remember = event.target.elements.remember.checked;

		wp.ajax
			.post('ajaxlogin', {
				username,
				password,
				security,
				remember,
			})
			.done((data) => {
				console.log(data);

				this.loginBtn.classList.remove('disabled');
				this.loginBtn.innerHTML = btnText;

				if (data && data.loggedin == true) {
					/* Google TAG */
					window.dataLayer.push({
						event: 'login',
						method: 'site',
					});

					MicroModal.close('login-modal');
					//if body has class 'page-template-page-password-lost' or 'page-template-page-password-reset'
					if (
						document.body.classList.contains(
							'page-template-page-password-lost'
						) ||
						document.body.classList.contains(
							'page-template-page-password-reset'
						)
					) {
						document.location.href = ajax_login_object.dashboardurl;
					} else {
						document.location.href = ajax_login_object.redirecturl;
					}
				}
			})
			.fail((error) => {
				//manage error
				this.loginBtn.classList.remove('disabled');
				this.loginBtn.innerHTML = btnText;

				document.getElementById('login-notifications').textContent =
					error.message;
			});
	}
}
