const DEV = process.env.NODE_ENV === 'development'

export default {
  init() {
    //alert('TEX STAFFX INNIX')
    document.querySelectorAll('.author-tabs-btn').forEach((button) => {
      button.addEventListener('click', function (e) {
        e.preventDefault()
        // Remove active class from previously active button
        document
          .querySelector('.author-tabs-btn.active')
          ?.classList.remove('active')
        // Add active class to the clicked button
        this.classList.add('active')

        // Remove active class from previously active content
        document
          .querySelector('.author-tabs-content.active')
          ?.classList.remove('active')
        // Add active class to the corresponding content
        const target = this.getAttribute('data-target')
        document.getElementById(`content-${target}`).classList.add('active')
      })
    })
  },
  finalize() {},
}
