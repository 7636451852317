//import Notiflix from 'notiflix';
import { Notify } from 'notiflix/build/notiflix-notify-aio';

export default class Pdf {
	constructor() {
		window.addEventListener('load', () => {
			this.init();
		});
	}

	init() {
		this.elements = document.querySelectorAll('.generatePdf');
		this.elements.forEach((element) => {
			element.addEventListener('click', (event) =>
				this.generatePDF(event)
			);
		});
	}

	toggleLoading() {
		this.elements = document.querySelectorAll('.generatePdf');
		this.elements.forEach((element) => {
			element.classList.toggle('load');
		});
	}

	generatePDF(event) {
		event.preventDefault();
		const postID = event.currentTarget.dataset.postid;
		const pdfnonce = event.currentTarget.dataset.pdfnonce;
		this.toggleLoading();

		window.dataLayer.push({
			event: 'DownloadPDF',
			subscribed: 'yes',
		});

		//check sull'oggetto subscription che controlla se l'utente può il
		Notify.warning(lgc_global.action_messages.pdf_start);

		wp.ajax
			.post('legc_pdf_download', {
				postID,
				pdfnonce,
			})
			.done((data) => {
				this.toggleLoading();
				Notify.success(data.message);
				this.download(data.download_link, data.filename);
			})
			.fail((error) => {
				this.toggleLoading();
				Notify.failure(error.message);
			});
	}

	download(url, filename) {
		fetch(url)
			.then((response) => response.blob())
			.then((blob) => {
				const link = document.createElement('a');
				link.href = URL.createObjectURL(blob);
				link.download = filename;
				link.click();
			})
			.catch(function (error) {
				console.log('Looks like there was a problem: ', error);
				Notify.failure(lgc_global.action_messages.pdf_error_download);
			});
	}
}
