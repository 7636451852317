const DEV = process.env.NODE_ENV === 'development'

export default {
  init() {
    const sections = document.querySelectorAll(".authors-sections");

    window.addEventListener("scroll", navHighlighter);

    function navHighlighter() {
      
      let scrollY = window.pageYOffset;
      
      sections.forEach(current => {
        const sectionHeight = current.offsetHeight;
        const sectionTop = (current.getBoundingClientRect().top + window.pageYOffset) - 50;
        const sectionId = current.getAttribute("id");
        if (
          scrollY > sectionTop &&
          scrollY <= sectionTop + sectionHeight
        ){
          document.querySelector("#authorsMenu a[href*=" + sectionId + "]").classList.add("text-red");
        } else {
          document.querySelector("#authorsMenu a[href*=" + sectionId + "]").classList.remove("text-red");
        }
      });
    }

  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
  },
}
