export default class Signup {
	constructor() {
		window.addEventListener('load', () => {
			this.init();
		});
	}

	init() {
		this.signupFormElement = document.getElementById('signup');
		if (this.signupFormElement) {
			this.signupFormElement.addEventListener('submit', this.signup);
		}

		this.elements = document.querySelectorAll('.visibility-toggle');
		this.onElements = document.querySelectorAll('.visibility-toggle-off');
		this.offElements = document.querySelectorAll('.visibility-toggle-on');

		this.passwords = document.querySelectorAll('[type="password"]');

		// add a click event to each element
		this.elements.forEach((element) => {
			element.addEventListener('click', (event) =>
				this.setPasswordToggle(event)
			);
		});
	}
	setPasswordToggle(event) {
		this.passwords.forEach((password) => {
			password.type = password.type === 'password' ? 'text' : 'password';
		});
		this.onElements.forEach((element) => {
			element.classList.toggle('hidden');
		});

		this.offElements.forEach((element) => {
			element.classList.toggle('hidden');
		});
	}
	signup(event) {
		event.preventDefault();

		/* Google TAG */
		window.dataLayer.push({
			event: 'sign_up',
			method: 'site',
		});

		const email = event.target.elements.email.value;
		const password = event.target.elements.password.value;
		const passwordConfirm = event.target.elements['password-confirm'].value;
		const security = event.target.elements['security-registration'].value;
		const firstname = event.target.elements.firstname.value;
		const lastname = event.target.elements.lastname.value;
		const privacy = event.target.elements.privacy.checked;

		wp.ajax
			.post('ajaxsignup', {
				password,
				passwordConfirm,
				email,
				security,
				firstname,
				lastname,
				privacy,
			})
			.done((data) => {
				if (data && data.signup == true) {
					document.location.href = data.redirecturl;
				}
			})
			.fail((error) => {
				//manage error
				document.getElementById('register-notifications').textContent =
					error['message'];
			});
	}
}
