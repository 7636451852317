//import Notiflix from 'notiflix';
import { Notify } from 'notiflix/build/notiflix-notify-aio'
import MicroModal from 'micromodal' // es6 module

export default {
  init() {
    let whiteListed = false
    let userType = lgc_global.user_data.user_type
    let bannerType // magari meglio prendere anche questo da json
    let banners_data = JSON.parse(lgc_global.banners_data)
    let alt_banner
    let elections_banner

    const markupDisposableBanner = function (data) {
      const markup = `<div class="disposableBanner pointer-events-none  fixed top-0 left-0 bottom-0 right-0 z-100">
        <div class="pw-backdrop bg-gradient-to-b from-transparent to-black opacity-0 transition-opacity fixed top-0 left-0 w-full h-full"></div>
        <div class="pw-content pointer-events-auto transition-all translate-y-full opacity-0 py-5 fixed bottom-0 left-0 w-full bg-grey-lightest text-${
          data.alignment
        }" ${
        data.background_color
          ? 'style="background-color:' + data.background_color + ';"'
          : ''
      }>
          <div class="px-4 mx-auto max-w-[975px] relative ${
            data.white_text ? 'text-white' : ''
          }">
            <div class="relative lg:max-w-[685px]">
              <div class="relative z-1">
                <div class="font-sans tracking-wide uppercase font-bold text-sm mr-8 ${
                  data.white_text ? 'text-white' : 'text-red'
                }">${data.label}</div>
                <h3 class="font-display font-semibold text-2xl md:text-3xl mt-4">${
                  data.title
                }</h3>
                <div class="font-sans text-sm md:text-l pr-24 md:pr-40 lg:pr-8 mt-2 mb-4">${
                  data.text
                }</div>
                  ${
                    data.button
                      ? `<a href="${data.button.url}" target="${
                          data.button.target
                        }" class="redirect_btn  ${
                          data.white_text
                            ? 'btn-outline btn-outline-white'
                            : 'btn-red'
                        } md:min-w-[345px]">${data.button.title}</a>`
                      : ''
                  }
                  <br/><span class='inline-block font-sans mt-4'>
                  ${data.login_txt_before ? data.login_txt_before : ''}
                  ${
                    data.login_link_txt
                      ? `
                  <a class='banner-login-btn text-red' href='#'>
                    ${data.login_link_txt}
                  </a> `
                      : ''
                  }
                  ${data.login_txt_after ? data.login_txt_after : ''}
                </span>
              </div>
              ${
                data.image
                  ? `<img src="${data.image.url}" class="absolute right-0 lg:right-auto lg:left-full bottom-20 lg:bottom-1/2 translate-y-1/2 w-[90px] h-[90px] md:w-[170px] md:h-[170px] object-contain mix-blend-multiply"/>`
                  : ''
              }              
            </div>
            <div id="close-banner-btn" class="absolute p-4 right-2 -top-4 cursor-pointer z-10 ${
              data.white_text ? 'text-black' : 'text-red'
            }">
              <svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 14" width="15" height="14">
                  <path d="M 1.3710938 0 L 0 1.4570312 L 6.296875 7.3789062 L 0 13.300781 L 1.3710938 14.757812 L 7.7578125 8.7519531 L 14.142578 14.757812 L 15.513672 13.300781 L 9.2167969 7.3789062 L 15.513672 1.4570312 L 14.142578 0 L 7.7578125 6.0058594 L 1.3710938 0 z " />
              </svg>
            </div>
          </div>
        </div>
        </div>`
      return markup
    }
    const markupFixBanner = function (data) {
      const markup = `<div id="fix-pw" class="pointer-events-none fixed top-0 left-0 right-0 bottom-0 z-100">
          <div class="pw-backdrop bg-gradient-to-b from-transparent to-black opacity-0 transition-opacity fixed top-0 left-0 w-full h-full"></div>
          <div class="pw-content pointer-events-auto transition-all translate-y-full opacity-0 py-10 fixed bottom-0 left-0 w-full bg-grey-lightest text-${
            data.alignment
          }" ${
        data.background_color
          ? 'style="background-color:' + data.background_color + ';"'
          : ''
      }> 
            <div class="px-4 mx-auto max-w-[975px] relative ${
              data.white_text ? 'text-white' : ''
            }">
              <div class="relative">
                ${
                  data.image
                    ? `<img src="${data.image.url}" class="lg:absolute lg:left-9/10 xl:left-full bottom-1/2 lg:translate-y-1/2 w-[50px] h-[50px] lg:w-[170px] lg:h-[170px] mx-auto mb-4 lg:mb-0 object-contain mix-blend-multiply"/>`
                    : ''
                }
                <div class="relative z-1">
                    <div class="font-sans tracking-wide uppercase font-bold text-sm mr-8 ${
                      data.white_text ? 'text-white' : 'text-red'
                    }">${data.label}</div>
                    <h3 class="font-display font-semibold text-2xl md:text-3xl mt-4 lg:mt-10">${
                      data.title
                    }</h3>
                    <div class="font-sans text-sm md:text-l mt-4 mb-8 max-w-[570px] mx-auto">${
                      data.text
                    }</div>
                    ${
                      data.button
                        ? `<a href="${data.button.url}" target="${
                            data.button.target
                          }" class="redirect_btn ${
                            data.white_text
                              ? 'btn-outline btn-outline-white'
                              : 'btn-red'
                          } md:min-w-[345px]">${data.button.title}</a>`
                        : ''
                    }
                    <br/><span class='inline-block font-sans mt-4'>
                      ${data.login_txt_before ? data.login_txt_before : ''}
                      ${
                        data.login_link_txt
                          ? `
                      <a class='banner-login-btn text-red' href='#'>
                        ${data.login_link_txt}
                      </a> `
                          : ''
                      }
                      ${data.login_txt_after ? data.login_txt_after : ''}
                    </span>
                </div>               
              </div>
            </div>
          </div>
        </div>`
      return markup
    }
    const getBannerData = function (user, type) {
      //console.log(banners_data);
      if (banners_data) {
        for (var x = 0; x < banners_data.length; x++) {
          var curitem = banners_data[x]
          if (curitem.type == type && curitem.user == user) {
            return curitem
          }
        }
      }
      //ERROR No banner FOund:
      console.log('No banner was found for: ' + user + '_' + type)
    }
    const setCookie = function (cname, cvalue, exdays) {
      const d = new Date()
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
      let expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
    }
    const getCookie = function (cname) {
      let name = cname + '='
      let decodedCookie = decodeURIComponent(document.cookie)
      let ca = decodedCookie.split(';')
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ''
    }

    // let triggerBlock = (entries, observer) => {
    //   entries.forEach((entry) => {
    //     console.log(entry.target)
    //     // Each entry describes an intersection change for one observed
    //     // target element:
    //     //   entry.boundingClientRect
    //     //   entry.intersectionRatio
    //     //   entry.intersectionRect
    //     //   entry.isIntersecting
    //     //   entry.rootBounds
    //     //   entry.target
    //     //   entry.time
    //   })
    //   if (document.getElementById('fix-pw')) {
    //     document.getElementById('fix-pw').classList.add('pw-show')
    //   }
    // }
    // let options = {
    //   //      root: document.querySelector('.article-body'),
    //   rootMargin: '0px',
    //   threshold: 1.0,
    // }

    // let observer = new IntersectionObserver(triggerBlock, options)
    // let target = document.querySelector('.wp-block-lgc-keypoints')
    // observer.observe(target)

    let lastKnownScrollPosition = 0
    let ticking = false

    let triggerPos = 1100
    let firstIframe = document.querySelector('.iframe-container')
    let keypoints = document.querySelector('.keys')
    if (firstIframe) {
      console.log('FF')
      triggerPos = firstIframe.getBoundingClientRect().bottom
    } else if (keypoints) {
      console.log('KP')
      triggerPos = keypoints.getBoundingClientRect().top
    }
    console.log('--')
    const showPaywallOnScroll = function (e) {
      lastKnownScrollPosition = window.scrollY
      //console.log(lastKnownScrollPosition)
      if (!ticking) {
        window.requestAnimationFrame(function () {
          if (lastKnownScrollPosition > triggerPos) {
            window.removeEventListener('scroll', showPaywallOnScroll)
            document.getElementById('fix-pw').classList.add('pw-show')
            var style = document.createElement('style')
            style.innerHTML = `
              .pw {
              height:${Math.round(triggerPos + 700)}px !important;
              }
              `
            document.head.appendChild(style)
            window.dataLayer.push({
              event: 'PaywallViews',
              paywallType: 'paywall',
            })
            document
              .querySelector('.pw-content .redirect_btn')
              .addEventListener('click', function () {
                window.dataLayer.push({
                  event: 'ClickSubscribe',
                  origin: 'paywall',
                })
                return true
              })
          }
          ticking = false
        })
        ticking = true
      }
    }
    const createBanner = function (userType, bannerType) {
      if (!userType || !bannerType) return

      let bannerTypeContent = alt_banner ? bannerType + '_alt' : bannerType
      if (elections_banner) {
        bannerTypeContent = bannerType + '_elections'
      }
      let data = getBannerData(userType, bannerTypeContent)
      let banner = null
      if (data) {
        banner =
          bannerType == 'paywall'
            ? markupFixBanner(data)
            : markupDisposableBanner(data)
        attachBanner(banner, bannerType)
      }
    }
    const attachBanner = function (banner, bannerType) {
      document.querySelectorAll('.disposableBanner').forEach((element) => {
        element.remove()
      })

      switch (bannerType) {
        case 'paywall':
          document.body.classList.add('pw')
          document
            .getElementsByClassName('full-article')[0]
            .classList.add('full-article-r')
          //window.scrollTo({top: 0, behavior: 'auto' });
          document.body.insertAdjacentHTML('beforeend', banner)
          document.addEventListener('scroll', showPaywallOnScroll)
          break
        case 'softwall':
          document.body.insertAdjacentHTML('beforeend', banner)
          document
            .getElementById('close-banner-btn')
            .addEventListener('click', function () {
              setCookie('swc', 'true', 1)
              document.body.classList.remove('db-show')
              window.dataLayer.push({
                event: 'ClickClosePayWall',
                paywallType: 'softwall',
              })
            })
          document
            .querySelector('.pw-content .redirect_btn')
            .addEventListener('click', function () {
              window.dataLayer.push({
                event: 'ClickSubscribe',
                origin: 'softwall',
              })
              return true
            })
          window.setTimeout(() => document.body.classList.add('db-show'), 1500) //per farlo apparire con animazione
          window.dataLayer.push({
            event: 'PaywallViews',
            paywallType: 'softwall',
          })
          break
        case 'save':
          document.body.insertAdjacentHTML('beforeend', banner)
          document
            .getElementById('close-banner-btn')
            .addEventListener('click', function () {
              document.body.classList.remove('db-show')
              window.dataLayer.push({
                event: 'ClickClosePayWall',
                paywallType: 'save',
              })
            })
          document
            .querySelector('.pw-content .redirect_btn')
            .addEventListener('click', function () {
              window.dataLayer.push({
                event: 'ClickSubscribe',
                origin: 'save',
              })
              return true
            })
          window.setTimeout(() => document.body.classList.add('db-show'), 10) //per farlo apparire con animazione
          window.dataLayer.push({
            event: 'PaywallViews',
            paywallType: 'save',
          })
          break
        case 'pdf':
          document.body.insertAdjacentHTML('beforeend', banner)
          document
            .getElementById('close-banner-btn')
            .addEventListener('click', function () {
              document.body.classList.remove('db-show')
              window.dataLayer.push({
                event: 'ClickClosePayWall',
                paywallType: 'pdf',
              })
            })
          document
            .querySelector('.pw-content .redirect_btn')
            .addEventListener('click', function () {
              window.dataLayer.push({
                event: 'ClickSubscribe',
                origin: 'pdf',
              })
              return true
            })
          window.setTimeout(() => document.body.classList.add('db-show'), 10) //per farlo apparire con animazione
          window.dataLayer.push({
            event: 'PaywallViews',
            paywallType: 'pdf',
          })
          break
      }

      MicroModal.init({ disableFocus: true })

      document.body.addEventListener('click', (event) => {
        if (event.target.matches('.banner-login-btn')) {
          event.preventDefault()
          MicroModal.show('login-modal')
        }
      })

      //show pawallBanner
      //show pdf banner
      //show save banner
      //show softwall banner
    }
    const initPaywall = function () {
      if (document.body.classList.contains('paywall')) {
        bannerType = 'paywall'
      } else if (document.body.classList.contains('softwall')) {
        bannerType = 'softwall'
        if (getCookie('swc')) {
          //return;
        }
      } else {
        return
      }

      if (document.body.classList.contains('alt_banner')) {
        alt_banner = true
      }
      if (
        document.body.classList.contains(
          'page-template-page-elections-observatory-home'
        ) ||
        document.body.classList.contains('single-election')
      ) {
        elections_banner = true
      }

      if (userType == 'subscribed') {
        return //no banners
      } else {
        // NO WHITELIST IP CHECK
        // if (userType == 'guest') {
        //   createBanner(userType, bannerType)
        // } else if (userType == 'registered') {
        //   if (bannerType == 'softwall') {
        //     createBanner(userType, bannerType)
        //   } else if (
        //     bannerType == 'paywall' &&
        //     !lgc_global.user_data.can_read_this
        //   ) {
        //     //se non ha più arrticoli disponibili di questo tipo:
        //     createBanner(userType, bannerType)
        //   }
        // }

        // CLOUDFLARE WHITELIST IP CHECK
        const apiUrl = 'https://ipwl.legrandcontinent.eu/test'

        //check if cookie exists and is valid
        let cookie = getCookie('ipwl')
        if (!cookie) {
          fetch(apiUrl)
            .then((response) => {
              if (!response.ok) {
                throw new Error('Network response was not ok')
              }
              return response.json()
            })
            .then((data) => {
              //console.log(data)
              //console.log(data.result)
              whiteListed = data.result // trtue / false

              //save result to cookie with 1 day expiration
              setCookie('ipwl', whiteListed, 1)

              if (whiteListed) {
                return
              } else if (userType == 'guest') {
                createBanner(userType, bannerType)
              } else if (userType == 'registered') {
                if (bannerType == 'softwall') {
                  createBanner(userType, bannerType)
                } else if (
                  bannerType == 'paywall' &&
                  !lgc_global.user_data.can_read_this
                ) {
                  //se non ha più arrticoli disponibili di questo tipo:
                  createBanner(userType, bannerType)
                }
              }
            })
            .catch((error) => {
              console.error('Error:', error)
            })

          // WHITELIST IP CHECK ON THE SERVER
          // wp.ajax
          //   .post('legc_get_user_ip_whitelisted')
          //   .done((data) => {
          //     whiteListed = data // trtue / false
          //     if (whiteListed) {
          //       return
          //     } else if (userType == 'guest') {
          //       createBanner(userType, bannerType)
          //     } else if (userType == 'registered') {
          //       if (bannerType == 'softwall') {
          //         createBanner(userType, bannerType)
          //       } else if (
          //         bannerType == 'paywall' &&
          //         !lgc_global.user_data.can_read_this
          //       ) {
          //         //se non ha più arrticoli disponibili di questo tipo:
          //         createBanner(userType, bannerType)
          //       }
          //     }
          //   })
          //   .fail((error) => {
          //     console.log(error)
          //   })
        } else {
          whiteListed = cookie === 'true' ? true : false
          if (whiteListed) {
            return
          } else if (userType == 'guest') {
            createBanner(userType, bannerType)
          } else if (userType == 'registered') {
            if (bannerType == 'softwall') {
              createBanner(userType, bannerType)
            } else if (
              bannerType == 'paywall' &&
              !lgc_global.user_data.can_read_this
            ) {
              //se non ha più arrticoli disponibili di questo tipo:
              createBanner(userType, bannerType)
            }
          }
        }
      }

      // wp.ajax.post('legc_get_user_subscription--').done((data) => {
      //       console.log(data);
      //   }).fail((error) => {
      //     }
      // });

      // check user (logged / registered / has access)
      // if no access -> show the correct banner
    }
    const initDisabledBtns = function () {
      let elements = document.querySelectorAll('.savePostDisabled')
      elements.forEach((element) => {
        element.addEventListener('click', (event) => {
          window.dataLayer.push({
            event: 'save_favorite',
            subscribed: 'no',
          })
          createBanner(userType, 'save')
        })
      })
      elements = document.querySelectorAll('.generatePdfDisabaled')
      elements.forEach((element) => {
        element.addEventListener('click', (event) => {
          window.dataLayer.push({
            event: 'DownloadPDF',
            subscribed: 'no',
          })
          createBanner(userType, 'pdf')
        })
      })
    }

    // init tasto pdf
    let promise = new Promise(function (resolve, reject) {
      //setTimeout(() => resolve("done!"), 1000);
      // setTimeout(() => reject(new Error("Whoops!")), 1000);
    })
    // resolve runs the first function in .then
    promise.then(
      (result) => alert(result), // shows "done!" after 1 second
      (error) => alert(error) // doesn't run
    )

    // init tasto salva

    // inizializzo paywall o softwall
    initPaywall()
    initDisabledBtns()
  },
}
