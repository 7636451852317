const DEV = process.env.NODE_ENV === 'development'
//import countriesChartsData from '../assets/countries-chart-data'
import ChartTemplateResolver from '../util/ChartTemplateResolver'
import { gsap, set } from 'gsap'

export default {
  init() {
    // gsap.to('.electoral-card', {
    //   duration: 0.01,
    //   //scale: 1,
    //   opacity: 0.2,
    // })

    function isTouchDevice() {
      return (
        'ontouchstart' in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      )
    }
    if (!isTouchDevice()) {
      const sliders = document.querySelectorAll('#cards-container')
      let mouseDown = false
      sliders.forEach((slider) => {
        let startX, scrollLeft

        let startDragging = function (e) {
          mouseDown = true
          startX = e.pageX - slider.offsetLeft
          scrollLeft = slider.scrollLeft
        }
        let stopDragging = function (event) {
          mouseDown = false
        }
        slider.addEventListener('mousemove', (e) => {
          e.preventDefault()
          if (!mouseDown) {
            return
          }
          const x = e.pageX - slider.offsetLeft
          const scroll = x - startX
          slider.scrollLeft = scrollLeft - scroll
          //console.log(slider.scrollLeft)
        })
        // Add the event listeners
        slider.addEventListener('mousedown', startDragging, false)
        slider.addEventListener('mouseup', stopDragging, false)
        slider.addEventListener('mouseleave', stopDragging, false)
      })
    }

    const scrollIntoViewWithOffset = (selector, offset) => {
      window.scrollTo({
        behavior: 'smooth',
        top:
          document.querySelector(selector).getBoundingClientRect().top -
          document.body.getBoundingClientRect().top -
          offset,
      })
    }

    //console.log(countriesChartsData)

    //show country name tooltip
    const countryTooltip = document.getElementById('countryTooltip')

    // Get the root element
    var rootStyle = getComputedStyle(document.documentElement)
    const min_card_width = rootStyle.getPropertyValue('--min-card-width')
    const max_card_width = rootStyle.getPropertyValue('--max-card-width')
    const electoralCards = document.querySelectorAll('.electoral-card')

    //horizontal.destroy(true);
    //horizontal = createHorizontal();

    let initial = document.getElementsByClassName('initial')[0]
    if (initial) {
      let openCard = initial
      let openCardInner = openCard.querySelector('.inner-card ')
      let openCardPreview = openCard.querySelector('.preview ')
      openCard.classList.add('open-card')
      // gsap.to(openCard, {
      //   background: '#ffffff',
      //   width: max_card_width,
      //   duration: 0.3,
      //   ease: 'strong.inOut',
      // })
      gsap.to(openCardInner, {
        opacity: 1,
        scale: 1,
        duration: 0.6,
        ease: 'strong.inOut',
      })
      gsap.to(openCardPreview, {
        opacity: 0,
        x: -100,
        duration: 0.6,
        ease: 'strong.inOut',
      })
    }
    electoralCards.forEach((card) => {
      card.addEventListener('click', function (e) {
        let oldCard = document.getElementsByClassName('open-card')[0]
        if (oldCard) {
          oldCard.classList.remove('open-card')
          let oldCardInner = oldCard.querySelector('.inner-card ')
          let oldCardPreview = oldCard.querySelector('.preview ')
          // gsap.to(oldCard, {
          //   background: 'rgba(255,255,255,0.4)',
          //   width: min_card_width,
          //   duration: 0.3,
          //   ease: 'strong.inOut',
          // })
          gsap.to(oldCardInner, {
            opacity: 0,
            scale: 0.8,
            duration: 0.6,
            ease: 'strong.inOut',
          })
          gsap.to(oldCardPreview, {
            opacity: 1,
            x: 0,
            duration: 0.6,
            ease: 'strong.inOut',
          })
        }
        e.currentTarget.classList.add('open-card')
        let openCard = e.currentTarget
        let openCardInner = openCard.querySelector('.inner-card ')
        let openCardPreview = openCard.querySelector('.preview ')
        // gsap.to(openCard, {
        //   background: '#ffffff',
        //   width: max_card_width,
        //   duration: 0.3,
        //   ease: 'strong.inOut',
        // })
        gsap.to(openCardInner, {
          opacity: 1,
          scale: 1,
          duration: 0.6,
          ease: 'strong.inOut',
        })
        gsap.to(openCardPreview, {
          opacity: 0,
          x: -100,
          duration: 0.6,
          ease: 'strong.inOut',
        })
      })
    })

    //open full calenda view
    const openCalendarBtn = document.getElementById('open-calendar')
    const closeCalendarBtn = document.getElementById('close-calendar')
    const calendar = document.getElementById('full-calendar')
    const calendarContent = document.getElementById('calendar-content')
    const pageContent = document.getElementById('page-content')
    openCalendarBtn.addEventListener('click', function (e) {
      e.preventDefault()
      //console.log('open calendar')
      //calendar.classList.remove('hidden')
      gsap.to(calendar, {
        x: 0,
        width: '100%',
        //autoAlpha: 1,
        duration: 0.6,
        ease: 'strong.inOut',
        onComplete: function () {
          const calendarHeight = calendar.offsetHeight
          calendar.classList.remove('pointer-events-none')
          pageContent.style.height = `${calendarHeight}px`
          pageContent.classList.add('overflow-hidden')
        },
      })
      gsap.to(calendarContent, {
        yPercent: 0,
        alpha: 1,
        duration: 0.4,
        delay: 0.4,
        ease: 'bezier(0.16, 1, 0.3, 1)',
      })
    })
    closeCalendarBtn.addEventListener('click', function (e) {
      e.preventDefault()
      //calendar.classList.add('hidden')
      gsap.to(calendar, {
        //x: '-100%',
        width: '0',
        //autoAlpha: 0,
        duration: 0.6,
        ease: 'strong.inOut',
        onComplete: function () {
          pageContent.style.height = 'auto'
          pageContent.classList.remove('overflow-hidden')
          calendar.classList.add('pointer-events-none')
        },
      })
      gsap.to(calendarContent, {
        yPercent: 2,
        alpha: 0,
        duration: 0.1,
        ease: 'strong.inOut',
      })
    })

    // scroll timeline
    const scrollPrevBtn = document.getElementById('scroll-prev')
    const scrollNextBtn = document.getElementById('scroll-next')
    const cardsContainer = document.getElementById('cards-container')
    function activateScrollBtns() {
      // console.log(
      //   'resize scrollLeft: ',
      //   cardsContainer.scrollLeft,
      //   cardsContainer.scrollWidth,
      //   cardsContainer.clientWidth
      // )
      if (
        cardsContainer.scrollWidth >
        cardsContainer.clientWidth + cardsContainer.scrollLeft + 5
      ) {
        scrollNextBtn.classList.remove('disabled')
      } else {
        scrollNextBtn.classList.add('disabled')
      }
      if (cardsContainer.scrollLeft > 1) {
        // console.log('>1 -  enabled')
        scrollPrevBtn.classList.remove('disabled')
      } else {
        // console.log('<=1 -  disabled')
        scrollPrevBtn.classList.add('disabled')
      }
    }
    window.addEventListener('resize', activateScrollBtns)
    window.dispatchEvent(new Event('resize'))
    scrollPrevBtn.addEventListener('click', function () {
      cardsContainer.scrollLeft -= 240
      scrollNextBtn.classList.remove('disabled')
      setTimeout(activateScrollBtns, 500)
    })
    scrollNextBtn.addEventListener('click', function () {
      cardsContainer.scrollLeft += 240
      scrollPrevBtn.classList.remove('disabled')
      setTimeout(activateScrollBtns, 500)
    })
    // end scroll timeline

    var timeout
    window.addEventListener('message', function (event) {
      //console.log('EVENT', event)
      if (
        typeof event.data === 'string' &&
        event.data === 'datawrapper:vis:rendered'
      ) {
        // basic polling on the event, take the last
        // clearTimeout(timeout)
        // timeout = setTimeout(() => {
        //   enableButtons()
        //   // const spinner = document.getElementById('lgc-charts-spinner')
        //   // spinner.remove()
        // }, 500)
      }
    })

    const buttons = document.getElementsByClassName('change-chart-btn')

    const enableButtons = () => {
      Array.from(buttons).forEach((btn) => {
        btn.disabled = false
      })
    }

    const disableButtons = () => {
      Array.from(buttons).forEach((btn) => {
        btn.disabled = true
      })
    }

    const hideCountryTooltip = () => {
      gsap.to(countryTooltip, {
        autoAlpha: 0,
        duration: 0.3,
        overwrite: true,
      })
    }
    const hideCountryTooltipImmediatly = () => {
      countryTooltip.style.opacity = 0
      gsap.to(countryTooltip, {
        autoAlpha: 0,
        duration: 0,
        overwrite: true,
      })
    }
    const showCountryTooltip = () => {
      gsap.to(countryTooltip, {
        autoAlpha: 1,
        duration: 0.2,
        overwrite: true,
      })
    }

    Array.from(buttons).forEach((btn) => {
      const btnWidth = btn.getBoundingClientRect().width / 2
      btn.addEventListener('click', function (e) {
        //clearTimeout(timeout) // just in case
        hideCountryTooltipImmediatly()
        e.preventDefault()
        e.stopPropagation()
        //remove active class from .change-chart-btn if exists
        Array.from(buttons).forEach((btn) => {
          btn.classList.remove('active')
        })
        e.currentTarget.classList.add('active')

        scrollIntoViewWithOffset('#graph-container', '112')
        const resolver = new ChartTemplateResolver()
        const { country } = e.currentTarget.dataset
        const rowsToDisplay = countriesChartsData[country]['components']
        const countryPageBtn = document.getElementById('linkToCountryPage')
        const countryPageBtn2 = document.getElementById('linkToCountryPage2')
        const countryLinkLabel = document.getElementById('countryLinkLabel')
        const countryLinkLabel2 = document.getElementById('countryLinkLabel2')
        if (countryPageBtn) {
          countryPageBtn.classList.remove('hidden')
          countryLinkLabel.className = 'country-' + country
          countryPageBtn.href = countriesChartsData[country]['url']
        }
        if (countryPageBtn2) {
          countryPageBtn2.classList.remove('hidden')
          countryLinkLabel2.className = 'country-' + country
          countryPageBtn2.href = countriesChartsData[country]['url']
        }

        //disableButtons()

        const container = document.getElementById('graph-container')
        //container.innerHTML = ''

        if (!rowsToDisplay) {
          //enableButtons()
          return
        }

        const innerdiv = document.createElement('div')
        rowsToDisplay.forEach((row) => {
          if (row.variant === 'chart') {
            let div = document.createElement('div')
            let classes = ['lg:flex', 'mb-8', 'lg:gap-8']
            div.classList.add(...classes)
            //div.style.gridTemplateColumns = `repeat(${row.charts.length}, minmax(0, 1fr))`
            innerdiv.appendChild(div)

            row.charts.forEach((chart) => {
              //if there is more then one colum, ignore the maxWidth setting
              if (row.charts.length > 1) {
                chart.setMaxWidth = false
              }
              const chartDiv = resolver.resolveChartTemplate(chart)
              div.appendChild(chartDiv)
            })
          } else if (row.variant === 'text') {
            let div = document.createElement('div')
            let div2 = document.createElement('div')
            let classes = ['mb-8']
            let classes2 = ['mx-auto', 'w-full', '!max-w-[700px]']
            div.classList.add(...classes)
            div2.classList.add(...classes2)
            div.appendChild(div2)
            let title = document.createElement('h2')
            let titleClasses = [
              '!font-display',
              '!font-normal',
              '!text-xl',
              'md:!text-2-5xl',
              '!leading-7',
            ]
            title.classList.add(...titleClasses)
            let textDiv = document.createElement('div')
            let textClasses = ['text-styled']
            textDiv.classList.add(...textClasses)
            title.innerText = row.title
            textDiv.innerHTML = row.text
            div2.appendChild(title)
            div2.appendChild(textDiv)
            //div.style.gridTemplateColumns = `repeat(${row.charts.length}, minmax(0, 1fr))`
            innerdiv.appendChild(div)
          } else if (row.variant === 'section_header') {
            let div = document.createElement('div')
            let classes = ['mb-8']
            div.classList.add(...classes)
            let title = document.createElement('h2')
            let titleClasses = [
              'chart-title',
              'font-bold',
              'font-sans',
              'text-2xl',
              'text-dark',
              'mb-2',
              'relative',
            ]
            title.classList.add(...titleClasses)
            let textDiv = document.createElement('div')
            let textClasses = [
              'chart-subtitle',
              'font-normal',
              'font-sans',
              'text-root-m',
              'text-grey',
              'mb-4',
              'relative',
            ]
            textDiv.classList.add(...textClasses)
            title.innerHTML = row.section_title
            textDiv.innerHTML = row.section_description
            div.appendChild(title)
            div.appendChild(textDiv)
            //div.style.gridTemplateColumns = `repeat(${row.charts.length}, minmax(0, 1fr))`
            innerdiv.appendChild(div)
          } else if (row.variant === 'boxes') {
            let div = document.createElement('div')
            //let classes = ['mb-8', 'lg:flex', 'gap-4']
            let classes = ['mb-8', 'boxes-carousel', 'gap-4']

            div.classList.add(...classes)
            innerdiv.appendChild(div)

            row.boxes.forEach((box) => {
              let boxDiv = document.createElement('div')
              let classes = [
                'p-4',
                'xl:p-3',
                'border',
                'border-grey-box',
                'flex-1',
                'mb-4',
                'min-w-[280px]',
                'w-[280px]',
                'flex',
                'flex-col',
                'mr-4',
              ]
              boxDiv.classList.add(...classes)

              let header = document.createElement('div')
              let headerClasses = ['flex', 'items-center']
              header.classList.add(...headerClasses)
              boxDiv.appendChild(header)

              if (box.color) {
                let color = document.createElement('span')
                let colorClasses = [
                  'w-3',
                  'h-3',
                  'rounded-full',
                  'mr-2',
                  '-mt-1',
                ]
                color.classList.add(...colorClasses)
                color.style.background = box.color
                header.appendChild(color)
              }
              if (box.title) {
                let title = document.createElement('h6')
                let titleClasses = ['font-display', 'font-normal', 'text-mb']
                title.classList.add(...titleClasses)
                title.innerHTML = box.title
                header.appendChild(title)
              }

              if (box.subtitle) {
                let subtitle = document.createElement('h6')
                let subtitleClasses = [
                  'font-sans',
                  'text-dark',
                  'text-xs',
                  'leading-4',
                  'lg:mx-0',
                ]
                subtitle.classList.add(...subtitleClasses)
                subtitle.innerHTML = box.subtitle
                boxDiv.appendChild(subtitle)
              }

              let text = document.createElement('p')
              let textClasses = [
                'font-sans',
                'text-grey',
                'text-[16px]',
                'pt-2',
                'mt-auto',
              ]
              text.classList.add(...textClasses)
              text.innerHTML = box.text
              boxDiv.appendChild(text)

              div.appendChild(boxDiv)
            })
          } else if (row.variant === 'iframe') {
            let div = document.createElement('div')
            let iframe = document.createElement('iframe')
            let classes = [
              'mb-8',
              'w-full',
              'relative',
              'pb-[150%]',
              'md:pb-[54%]',
            ]
            let classes2 = ['w-full', 'h-full', 'absolute', 'top-0', 'left-0']
            div.classList.add(...classes)
            iframe.classList.add(...classes2)
            if (row.class) {
              div.classList.add(row.class)
            }
            div.appendChild(iframe)

            iframe.src = row.src
            //div.style.gridTemplateColumns = `repeat(${row.charts.length}, minmax(0, 1fr))`
            innerdiv.appendChild(div)
          }
        })

        container.innerHTML = ''
        container.appendChild(innerdiv)
        window.createBoxesCarousel()
        let additionalContainer = document.getElementById(
          'graph-container-additional'
        )
        if (additionalContainer) {
          additionalContainer.remove()
        }

        //   '<script type="text/javascript" defer src="https://datawrapper.dwcdn.net/5eP9A/embed.js" charset="utf-8"></script><noscript><img src="https://datawrapper.dwcdn.net/5eP9A/full.png" alt="" /></noscript>'
      })

      btn.addEventListener(
        'mouseover',
        function (e) {
          countryTooltip.innerHTML = e.currentTarget.attributes.title.value
          countryTooltip.style.left =
            e.currentTarget.getBoundingClientRect().left + btnWidth + 'px'
          showCountryTooltip()
        },
        false
      )
      btn.addEventListener('mouseout', hideCountryTooltip, false)
    })
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    //console.log('finalize common');
    // setTimeout(() => {
    //   gsap.fromTo(
    //     '.electoral-card',
    //     {
    //       opacity: 0.2,
    //       x: 30,
    //     },
    //     {
    //       duration: 0.3,
    //       opacity: 1,
    //       x: 0,
    //       stagger: 0.05,
    //       onComplete: function () {
    //         document
    //           .getElementById('cards-container')
    //           .classList.remove('prestart')
    //       },
    //     }
    //   )
    // }, 3000)
  },
}
