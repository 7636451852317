export default class Newsletter {
  constructor() {
    window.addEventListener('load', () => {
      this.init()
    })
  }

  init() {
    document.querySelectorAll('.nwsl-unsubscribe').forEach((item) => {
      item.addEventListener('click', (event) => {
        event.currentTarget.classList.add('pointer-events-none', 'opacity-50')
        this.unsubscribeFromList(
          event.currentTarget.dataset.userid,
          event.currentTarget.dataset.listid
        )
      })
    })
    document.querySelectorAll('.nwsl-subscribe').forEach((item) => {
      item.addEventListener('click', (event) => {
        event.currentTarget.classList.add('pointer-events-none', 'opacity-50')
        this.subscribeToList(
          event.currentTarget.dataset.userid,
          event.currentTarget.dataset.listid
        )
      })
    })
    if (document.querySelector('#NewsletterLang')) {
      document
        .querySelector('#NewsletterLang')
        .addEventListener('change', function () {
          console.log(
            'selected language: ',
            document.querySelector('#NewsletterLang').value
          )
          document.activeElement.blur()
          this.classList.add('pointer-events-none', 'opacity-50')
          document
            .getElementById('newsletterLoading')
            .classList.remove('hidden')
          let userid = document.querySelector('#NewsletterLang').dataset.userid
          let langid = document.querySelector('#NewsletterLang').value
          wp.ajax
            .post('ajax_update_user_newsletter_lang', {
              userid,
              langid,
            })
            .done((data) => {
              this.classList.remove('pointer-events-none', 'opacity-50')
              document
                .getElementById('newsletterLoading')
                .classList.add('hidden')
              console.log('done updating lang')
              console.log(data)
              //window.location.reload()
            })
            .fail((error) => {
              document
                .getElementById('newsletterLoading')
                .classList.add('hidden')
              console.log('error updating lang')
              console.log(error)
              //window.location.reload()
            })
        })
    }
  }
  subscribeToList(userid, listid) {
    wp.ajax
      .post('ajax_subscribe_to_list', {
        userid,
        listid,
      })
      .done((data) => {
        console.log('done')
        console.log(data)
        window.location.reload()
      })
      .fail((error) => {
        console.log('error')
        console.log(error)
        window.location.reload()
      })
  }
  unsubscribeFromList(userid, listid) {
    console.log(userid + ' - ' + listid)
    wp.ajax
      .post('ajax_unsubscribe_from_list', {
        userid,
        listid,
      })
      .done((data) => {
        console.log('done')
        console.log(data)
        window.location.reload()
      })
      .fail((error) => {
        console.log('error')
        console.log(error)
        window.location.reload()
      })
  }
}
