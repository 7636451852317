import SlimSelect from 'slim-select'
import 'flickity/dist/flickity.min.css'
import Flickity from 'flickity'
require('flickity-fade')
require('flickity-imagesloaded')
import Popper from 'popper.js'
import tippy from 'tippy.js'
// import Sticky from "sticky-js";
import 'lazysizes'
import ScrollReveal from 'scrollreveal'
import { gsap } from 'gsap'

import * as ScrollMagic from 'scrollmagic' // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from 'gsap' // Also works with TweenLite and TimelineLite
import { CSSRulePlugin } from 'gsap/CSSRulePlugin'
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin'
import { CustomEase } from 'gsap/CustomEase'
import { SplitText } from 'gsap/SplitText.js'
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap'

gsap.registerPlugin(CSSRulePlugin, SplitText, DrawSVGPlugin, CustomEase)
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax)

console.log('main.js')

var pdf_warning = document.querySelector('.js-pdf-inactive')
if (pdf_warning) {
  pdf_warning.addEventListener('click', function (e) {
    document.querySelector('.tooltip-box').classList.add('open')
  })
}

var notes_block = document.getElementsByClassName('footnotes-block')[0]
var end_block = document.getElementById('end-content')
var last_block
if (notes_block) {
  last_block = notes_block.previousElementSibling
} else if (end_block) {
  last_block = end_block.previousElementSibling
}
if (last_block) {
  last_block.classList.add('last-element')
}

if (document.getElementsByClassName('page-template-page-about').length) {
  // GENERAL SETTING
  window.sr = ScrollReveal()

  // Custom Settings
  // sr.reveal('.tooltip', { delay: 1250, reset: false });

  var nums = {
    afterReveal: function (el) {
      el.classList.add('revealed')
    },
  }
  // sr.reveal('.slowNumber', options, 200 );

  var options = {
    opacity: null,
    duration: 500,
    delay: 400,
    reset: false,
    afterReveal: function (el) {
      el.classList.add('revealed')
    },
    afterReset: function (el) {
      el.classList.remove('revealed')
    },
  }

  var numbers = {
    opacity: null,
    duration: 500,
    delay: 100,
    reset: false,
    afterReveal: function (el) {
      el.classList.add('revealed')
    },
    afterReset: function (el) {
      el.classList.remove('revealed')
    },
  }

  var citations = {
    opacity: null,
    duration: 400,
    delay: 240,
    reset: false,
    afterReveal: function (el) {
      el.classList.add('revealed')
    },
    afterReset: function (el) {
      el.classList.remove('revealed')
    },
  }

  // sr.reveal('.underline-bold', options);

  var slideUp = {
    distance: '7rem',
    origin: 'bottom',
    delay: 0,
    duration: 700,
    easing: 'cubic-bezier(.02,.17,.18,.76)',
  }
  var fadeIn = {
    delay: 300,
    duration: 700,
  }
  sr.reveal('.numbers-container', numbers)
  sr.reveal('.fade-in', fadeIn)
  sr.reveal('.slide-up', slideUp)
  // sr.reveal('.iphone-frame', slideUp);
  sr.reveal('.citations_logos', citations)

  // gsap

  var controller = new ScrollMagic.Controller()

  // Main title animation
  document.addEventListener('DOMContentLoaded', function (event) {
    window.onload = titleAnim
    window.resize = titleAnim

    function titleAnim() {
      let atf = document.querySelector('.atf .wrapper')
      let title = document.querySelector('.atf h1')
      let splitTitle = new SplitText(title, { type: 'words' })
      let underline = title.querySelector('.underline-bold')
      let titleTL = gsap.timeline()
      let t_paths = atf.querySelectorAll('path')
      //console.log()

      if (document.body.classList.contains('home')) {
        titleTL
          .to(atf, { duration: 0.1, autoAlpha: 1 })
          .from(splitTitle.words, {
            stagger: 0.05,
            duration: 1.1,
            ease: CustomEase.create(
              'custom',
              'M0,0 C0.242,0.402 0.21,0.772 1,1'
            ),
            opacity: 0,
            y: 40,
          })
          .set(underline, { className: 'underline-bold revealed' }, '-=0.8')
          .staggerFromTo(
            t_paths,
            0.7,
            { drawSVG: '0% 0%' },
            { drawSVG: '0% 100%' }
          )

          //.from(".atf .arrow img", {duration: 1.5, ease: "power3.inOut", y: "-100%"}, "-=1.2")
          //.from(".atf .tooltip span", {duration: 1.5, opacity: 0, y: 10, ease: "power3.inOut"}, "-=1.4")
          .call(() => {
            atf.classList.add('show')
          })
      } else {
        titleTL
          .to(atf, { duration: 0.1, autoAlpha: 1 })
          .from(splitTitle.words, {
            stagger: 0.05,
            duration: 1.1,
            ease: CustomEase.create(
              'custom',
              'M0,0 C0.242,0.402 0.21,0.772 1,1'
            ),
            opacity: 0,
            y: 40,
          })
          .set(underline, { className: 'underline-bold revealed' }, '-=0.8')
          .from(
            '.atf .arrow img',
            { duration: 1.5, ease: 'power3.inOut', y: '-100%' },
            '-=1.2'
          )
          .from(
            '.atf .tooltip span',
            {
              duration: 1.5,
              opacity: 0,
              y: 10,
              ease: 'power3.inOut',
            },
            '-=1.4'
          )
          .call(() => {
            atf.classList.add('show')
          })
      }

      function unsplit() {
        splitTitle.revert()
      }
    }
  })

  // Caption animations
  if (document.querySelectorAll('.caption_anim').length !== 0) {
    document.querySelectorAll('.caption_anim').forEach((c) => {
      let span = c.querySelector('span')
      let h2 = c.querySelector('h2')
      let hr = c.querySelector('hr')
      let p = c.querySelector('p')
      let a = c.querySelector('a')
      let captionTL = gsap.timeline()
      captionTL
        .from(span, { duration: 0.2, opacity: 0, y: 10 })
        .from(h2, 0.2, { opacity: 0, y: 10 }, 0.2)
        .from(hr, 0.4, { ease: 'power3.inOut', width: 0 }, 0.1)
        .from(p, 0.3, { opacity: 0, y: 10 }, 0.2)
        .from(a, 0.3, { opacity: 0, x: 20 }, 0.4)

      new ScrollMagic.Scene({
        triggerElement: c,
        triggerHook: 0,
        offset: -600,
        reverse: false,
      })
        .setTween(captionTL)
        .addTo(controller)
    })
  }

  // Split text animations
  // basta applicare la class "split_text" al testo che si vuole scomporre
  if (document.querySelectorAll('.split_text').length !== 0) {
    document.querySelectorAll('.split_text').forEach((st) => {
      let text = new SplitText(st, { type: 'words' })
      let underline = st.querySelector('.underline-bold')

      let splitTL = gsap.timeline()
      splitTL
        .from(text.words, {
          stagger: 0.1,
          duration: 0.82,
          ease: CustomEase.create('custom', 'M0,0 C0.242,0.402 0.21,0.772 1,1'),
          opacity: 0,
          y: 40,
        })
        .add(function () {
          underline.classList.add('revealed')
        }, '-=0.2')
        .call(() => {
          splitTL.kill()
        })

      new ScrollMagic.Scene({
        triggerElement: st,
        triggerHook: 0.9,
      })
        .setTween(splitTL)
        .addTo(controller)
    })
  }

  // SVG animations
  // The svg path inside each container with the class "svg-anim" will be animated on scroll
  if (document.querySelectorAll('.svg-anim').length !== 0) {
    document.querySelectorAll('.svg-anim').forEach((svg_item) => {
      var h = svg_item.getBoundingClientRect().height
      let paths = svg_item.querySelectorAll('path')
      let svgTL = gsap.timeline()
      svgTL.staggerFromTo(
        paths,
        1,
        { drawSVG: '0% 0%' },
        { drawSVG: '0% 100%' },
        1
      )

      new ScrollMagic.Scene({
        triggerElement: svg_item,
        duration: h + 120,
        triggerHook: 0.5,
      })
        .setTween(svgTL)
        .addTo(controller)
    })
  }

  // Tooltip animations
  if (document.querySelectorAll('.tooltip').length !== 0) {
    document.querySelectorAll('.tooltip.t_anim').forEach((tt) => {
      let svg = tt.querySelector('svg')
      let tt_paths = tt.querySelectorAll('path')
      let tt_lines = tt.querySelectorAll('line')
      let tt_poly = tt.querySelectorAll('polyline')
      let tt_span = tt.querySelectorAll('span')
      let tt_div = tt.querySelectorAll('div')
      let ttTL = gsap.timeline()
      ttTL
        .delay(0.3)
        .staggerFromTo(
          tt_paths,
          0.25,
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100%' }
        )
        .staggerFromTo(
          tt_lines,
          0.3,
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100%' },
          '<'
        )
        .staggerFromTo(
          tt_poly,
          0.2,
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100%' },
          '<'
        )
        .staggerFrom(tt_span, 0.2, { opacity: 0, y: 15 }, '<')
        .staggerFrom(tt_div, 0.2, { opacity: 0, y: 15 }, '<')

      new ScrollMagic.Scene({
        triggerElement: svg,
        triggerHook: 0.9,
        offset: 0,
        reverse: false,
      })
        .setTween(ttTL)
        .addTo(controller)
    })
  }

  // Split text + Underline + Svg tooltip animations
  // apply "st-ul-sv_anim" class to container, "st_anim" to text and "tt_anim" to tooltip
  if (document.querySelectorAll('.st-ul-tt_anim').length !== 0) {
    document.querySelectorAll('.st-ul-tt_anim').forEach((el) => {
      let st = el.querySelector('.st_anim')
      let underline = st.querySelector('.underline-bold')
      //console.log(underline)
      let text_anim = new SplitText(st, { type: 'words' })
      //console.log(text_anim)
      let tt = el.querySelector('.tt_anim')
      let t_paths = tt.querySelectorAll('path')
      let t_lines = tt.querySelectorAll('line')
      let t_poly = tt.querySelectorAll('polyline')
      let t_text = tt.querySelector('.tt')

      let stulttTL = gsap.timeline()
      stulttTL
        .from(text_anim.words, {
          stagger: 0.1,
          duration: 0.82,
          ease: CustomEase.create('custom', 'M0,0 C0.242,0.402 0.21,0.772 1,1'),
          opacity: 0,
          y: 40,
        })
        .add(function () {
          underline.classList.add('revealed')
        }, '-=0.35')
        .staggerFromTo(
          t_paths,
          0.2,
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100%' }
        )
        .staggerFromTo(
          t_lines,
          0.2,
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100%' }
        )
        .staggerFromTo(
          t_poly,
          0.2,
          { drawSVG: '0% 0%' },
          { drawSVG: '0% 100%' }
        )
        .from(t_text, 0.2, { opacity: 0, y: 15 }, '-=0.2')
        .call(() => {
          stulttTL.kill()
        })

      new ScrollMagic.Scene({
        triggerElement: st,
        triggerHook: 0.9,
      })
        .setTween(stulttTL)
        .addTo(controller)
    })
  }

  // Text on blue background animation
  var europeTxtTL = new gsap.timeline()
  let europeText = document.querySelector('.europe_text')
  europeTxtTL
    .from(europeText, 2.5, { y: '200%' })
    .from(europeText, 1, { autoAlpha: 0 }, 1)
    .to(europeText, 0.5, { autoAlpha: 0 })

  new ScrollMagic.Scene({
    triggerElement: '.europe_text_trigger',
    duration: '140%',
    triggerHook: 0.9,
  })
    .setTween(europeTxtTL)
    .addTo(controller)

  document.addEventListener('DOMContentLoaded', function (event) {
    const Rellax = require('rellax')

    var rellax = new Rellax('.rellax', {
      speed: -2,
      center: true,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false,
      breakpoints: [576, 768, 990],
    })
  })

  //add simple support for background images:
  document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg')
    if (bg) {
      e.target.style.backgroundImage = 'url(' + bg + ')'
    }
  })

  //var sticky = new Sticky('.stick');
}
// .closest() polyfill
if (window.Element && !Element.prototype.closest) {
  Element.prototype.closest = function (s) {
    var matches = (this.document || this.ownerDocument).querySelectorAll(s),
      i,
      el = this
    do {
      i = matches.length
      while (--i >= 0 && matches.item(i) !== el) {}
    } while (i < 0 && (el = el.parentElement))
    return el
  }
}

// scrollbar width
let root = document.documentElement
var scrollDiv = document.createElement('div')
scrollDiv.className = 'scrollbar-measure'
document.body.appendChild(scrollDiv)
// Get the scrollbar width
var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth
root.style.setProperty('--scrollbar-width', scrollbarWidth + 'px')
// Delete the DIV
document.body.removeChild(scrollDiv)

var menuBtn = document.querySelector('.js-menu')
var menuBtnClose = document.querySelector('.js-menu-close')
var searchBtn = document.querySelector('.js-search')
var searchCloseBtn = document.querySelector('.js-close-search')
var searchBox = document.querySelector('.search-box')

// menu expand
if (menuBtn) {
  menuBtn.addEventListener('click', function (e) {
    document.body.classList.toggle('body--menu-active')
    menuBtn.classList.toggle('is-active')
    searchBox.classList.remove('is-active')
  })
}
// close menu expand
if (menuBtnClose) {
  menuBtnClose.addEventListener('click', function (e) {
    document.body.classList.remove('body--menu-active')
    menuBtn.classList.remove('is-active')
    searchBox.classList.remove('is-active')
  })
}

if (searchBtn) {
  // searchbox expand
  searchBtn.addEventListener('click', function (e) {
    searchBox.classList.toggle('is-active')
    document.getElementById('searchform-input').focus()
  })
  searchCloseBtn.addEventListener('click', function (e) {
    searchBox.classList.remove('is-active')
  })
}

// language expand
//new SlimSelect({
//  select: "#lang-select",
//  showSearch: false,
//  onChange: (info) => {
//    window.location.href = info.value;
//  }
//})

//BREVES

var briefing = document.getElementsByClassName('briefing-content')
Array.prototype.forEach.call(briefing, function (element, index, array) {
  var url = element.getAttribute('data-url')
  fetch(url)
    .then((response) => response.text())
    .then((html) => {
      const parser = new DOMParser()
      const htmlDocument = parser.parseFromString(html, 'text/html')
      const section =
        htmlDocument.documentElement.querySelector('.full-article')
      //document.querySelector("div").appendChild(section);
      element.appendChild(section)
    })
    .catch((error) => {
      console.warn(error)
    })
})

var accItem = document.getElementsByClassName('js-accordionItem')
var accHD = document.getElementsByClassName('js-accordionItemToggler')

for (var i = 0; i < accHD.length; i++) {
  accHD[i].addEventListener('click', toggleItem, false)
}
function toggleItem() {
  var closestParent = this.closest('.js-accordionItem')
  for (var i = 0; i < accItem.length; i++) {
    if (accItem[i] === closestParent) {
      closestParent.classList.toggle('open')
    } else {
      accItem[i].classList.remove('open')
    }
  }
}

/* FORM LABELS */

const Floaty = () => {
  function onFocus() {
    setLabelActive(this.floatingLabel)
  }
  function onBlur() {
    setLabel(this)
  }
  function setLabelActive(label) {
    label.classList.add('floating-label-active')
  }
  function setLabelInactive(label) {
    label.classList.remove('floating-label-active')
  }
  function setLabel(input = {}) {
    if (input.value && input.value.length) {
      setLabelActive(input.floatingLabel)
    } else {
      setLabelInactive(input.floatingLabel)
    }
  }

  const inputs = [].slice.call(document.querySelectorAll('input'))
  inputs.forEach((input) => {
    var label = input.nextElementSibling
    if (label) {
      if (label.classList.contains('floatLabel')) {
        input.floatingLabel = label
        input.addEventListener('focus', onFocus)
        input.addEventListener('blur', onBlur)
        setLabel(input)
      }
    }
  })
}
Floaty()

/*  SPEECHES COMMENTS */
var nextUntil = function (elem, selector) {
  // matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.msMatchesSelector ||
      Element.prototype.webkitMatchesSelector
  }

  // Setup siblings array
  var siblings = []
  // Get the next sibling element
  elem = elem.nextElementSibling
  // As long as a sibling exists
  while (elem) {
    // If we've reached our match, bail
    if (elem.matches(selector)) break

    // Otherwise, push it to the siblings array
    siblings.push(elem)
    // Get the next sibling element
    elem = elem.nextElementSibling
  }
  return siblings
}

document.querySelectorAll('.intro, .outro').forEach(function (el) {
  el.classList.add('fix-comment')
})
var fixedBlocks = document.querySelectorAll(
  ':not(.fix-comment) + .fix-comment, * > .fix-comment:first-of-type'
)
var commentsBlocks = document.querySelectorAll(
  ':not(.comment) + .comment, * > .comment:first-of-type'
)
var wrapper_template = document.getElementsByClassName('comment-block')[0]
for (var i = 0; i < commentsBlocks.length; i++) {
  var el = commentsBlocks[i]
  var wrapper = wrapper_template.cloneNode(true)
  wrapper.classList.remove('hidden')
  el.parentNode.insertBefore(wrapper, el)
  var sibillings = nextUntil(el, ':not(.comment)')

  var container = wrapper.getElementsByClassName('collapsable-block')[0]
  container.appendChild(el)
  for (var e = 0; e < sibillings.length; e++) {
    container.appendChild(sibillings[e])
  }
}
for (var i = 0; i < fixedBlocks.length; i++) {
  var el = fixedBlocks[i]
  var wrapper = document.createElement('div')
  wrapper.className = 'comment-block'
  el.parentNode.insertBefore(wrapper, el)
  var sibillings = nextUntil(el, ':not(.fix-comment)')

  wrapper.appendChild(el)
  for (var e = 0; e < sibillings.length; e++) {
    wrapper.appendChild(sibillings[e])
  }
}

var expandCommentBtns = document.getElementsByClassName('js-expand-comment')
for (var i = 0; i < expandCommentBtns.length; i++) {
  expandCommentBtns[i].addEventListener(
    'click',
    function () {
      this.parentNode.classList.toggle('open')
    },
    false
  )
}

if (wrapper_template) {
  wrapper_template.parentNode.removeChild(wrapper_template)
}

/*
var langBtn = document.querySelector('.js-lang');
var langSwithch = document.querySelector('.lang-switch');
langBtn.addEventListener('click', function(e) {
  document.querySelector('.lang-switch').classList.toggle('is-active');
});

langSwithch.onmouseout = function () {
		if (langSwithch.classList) {
			langSwithch.classList.remove('is-active');
		}
	}

*/

// geo tax home - carousel

var geo_group = document.getElementsByClassName('geo-home-carousel')
Array.prototype.forEach.call(geo_group, function (element, index, array) {
  var flkty_geo_tag = new Flickity(element, {
    // options
    prevNextButtons: false,
    setGallerySize: true,
    pageDots: false,
    cellAlign: 'left',
    contain: false,
    watchCSS: true,
  })
})

var briefing_hp_carousel = document.querySelectorAll('.briefing-hp-carousel')
Array.prototype.forEach.call(
  briefing_hp_carousel,
  function (element, index, array) {
    new Flickity(element, {
      prevNextButtons: true,
      pageDots: false,
      groupCells: true,
      cellAlign: 'left',
      contain: false,
      watchCSS: false,
    })
  }
)

// main category carousels
var tax_carousel_container = document.querySelector('.briefing-tax-carousel')
var flkty_brief_tax
if (tax_carousel_container) {
  flkty_brief_tax = new Flickity(tax_carousel_container, {
    // options
    prevNextButtons: true,
    pageDots: false,
    groupCells: true,
    cellAlign: 'left',
    contain: false,
    watchCSS: false,
  })

  flkty_brief_tax.on('change', function (index) {
    //console.log(index);
    //console.log(flkty_brief_tax.cells.length);
    if (flkty_brief_tax.cells.length - index * 4 < 4) {
      var url = lgc_global['ajaxurl']
      url += '?action=' + tax_carousel_container.getAttribute('data-action')
      url += '&term_id=' + tax_carousel_container.getAttribute('data-term_id')
      var next_page = tax_carousel_container.getAttribute('data-paged')
      url += '&paged=' + next_page
      //console.log(url);
      fetch(url)
        .then((response) => response.text())
        .then((html) => {
          if (html) {
            const parser = new DOMParser()
            const htmlDocument = parser.parseFromString(html, 'text/html')
            var elements =
              htmlDocument.getElementsByClassName('briefing-summary')
            flkty_brief_tax.append(elements)

            tax_carousel_container.setAttribute(
              'data-paged',
              parseInt(next_page, 10) + 1
            )
          }
        })
        .catch((error) => {
          console.warn(error)
        })
    }
  })
}

//elections boxes carousel
function setSliderHeightToMax(slider) {
  slider.cells.forEach((cell) => (cell.element.style.height = ''))
  let heights = slider.cells.map((cell) => cell.element.offsetHeight),
    max = Math.max.apply(Math, heights)
  slider.cells.forEach((cell) => (cell.element.style.height = max + 'px'))
}
window.boxes_slider = []
window.createBoxesCarousel = function () {
  const flkty_boxes = document.querySelectorAll('.boxes-carousel')
  window.boxes_slider = []
  if (flkty_boxes) {
    flkty_boxes.forEach((boxes_carousel_container) => {
      let cellsNumber = boxes_carousel_container.childElementCount
      let cellAlignValue = cellsNumber > 3 ? 'left' : 'center'
      if (cellsNumber <= 3) {
        boxes_carousel_container.classList.add('hide-arrows-desktop')
      }
      //console.log(election_submenu.childElementCount + ' celle')

      let slider = new Flickity(boxes_carousel_container, {
        // options
        prevNextButtons: true,
        pageDots: false,
        freeScroll: true,
        //groupCells: true,
        cellAlign: cellAlignValue,
        contain: true,
        //contain: false,
        //watchCSS: true,
        on: {
          ready: function () {
            setSliderHeightToMax(this)
          },
        },
      })
      window.boxes_slider.push(slider)
    })
  }
}
createBoxesCarousel()

window.addEventListener('resize', function () {
  if (boxes_slider) {
    boxes_slider.forEach((slider) => setSliderHeightToMax(slider))
  }
})

// elections home carousel
var election_briefing_carousel = document.querySelector(
  '.election-breves-carousel'
)
if (election_briefing_carousel) {
  //alert('election_briefing_carousel')
  var flkty_elections = new Flickity(election_briefing_carousel, {
    // options
    prevNextButtons: false,
    pageDots: false,
    cellAlign: 'left',
    contain: false,
    //watchCSS: true,
  })
}

// elections home carousel
var election_submenu = document.querySelector('.elections-submenu')
if (election_submenu) {
  //alert('election_briefing_carousel')
  var flkty_elections_sub = new Flickity(election_submenu, {
    // options
    prevNextButtons: false,
    freeScroll: true,
    pageDots: false,
    cellAlign: 'left',
    contain: true,
    //watchCSS: true,
  })
}

// homnepage carousels
var home_briefing_carousel = document.querySelector('.bulletin-carousel')
if (home_briefing_carousel) {
  var flkty_bulletin = new Flickity(home_briefing_carousel, {
    // options
    prevNextButtons: false,
    pageDots: false,
    cellAlign: 'left',
    contain: false,
    watchCSS: true,
  })
}
var books_carousel = document.querySelector('.books-carousel')
if (books_carousel) {
  var books_options = {
    prevNextButtons: false,
    pageDots: false,
    cellAlign: 'center',
    contain: false,
    groupCells: false,
    wrapAround: true,
    imagesLoaded: true,
    // adaptiveHeight: true
  }

  // enable prev/next buttons at 768px
  if (matchMedia('screen and (min-width: 450px)').matches) {
    books_options.cellAlign = 'left'
    books_options.groupCells = true
    books_options.pageDots = true
    books_options.wrapAround = false
  }

  // disable draggable at 1200px
  // if ( matchMedia('screen and (min-width: 1200px)').matches ) {
  //   books_options.draggable = false;
  // }

  var flkty_books = new Flickity(books_carousel, books_options)
  // if (flkty_books.selectedElement) {
  //   flkty_books.reposition();
  // }
  // flkty_books.on("select", function() {
  //   flkty_books.reposition();
  // });
}
/*
var flkty_subscription = new Flickity( '.NOsubscription-carousel', {
  watchCSS: false,
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'center',
  contain: false,
  setGallerySize: false,
  wrapAround: false
});

flkty_subscription.on( 'select', function(cell) { 
});
*/

/* SCROLL NAVIGATION  */

const scrollNav = document.querySelector('#scroll_nav')
const scrollNavPicture = document.querySelector('#scrollNavPicture')
const specialSide = document.querySelector('#special-side')
if (scrollNav || scrollNavPicture) {
  const nav = document.querySelector('#masthead')
  const navTop = nav.offsetTop + nav.offsetHeight

  function stickyNavigation() {
    // console.log('navTop = ' + navTop);
    // console.log('scrollY = ' + window.scrollY);
    if (window.shareIsOpen) {
      document.querySelector('.share-box').classList.remove('expanded')
      window.shareIsOpen = false
    }
    var scrollTop =
      document.documentElement['scrollTop'] || document.body['scrollTop']
    var scrollBottom =
      (document.documentElement['scrollHeight'] ||
        document.body['scrollHeight']) - document.documentElement.clientHeight
    var scrollPercent = (scrollTop / scrollBottom) * 100
    //console.log(scrollTop);
    if (specialSide) {
      if (scrollTop > 1400) {
        specialSide.classList.add('show_n')
      } else {
        specialSide.classList.remove('show_n')
      }
    }
    document
      .getElementById('_progress')
      .style.setProperty('--scroll', scrollPercent + '%')

    if (window.scrollY >= navTop) {
      document.body.classList.add('fixed-nav')
    } else {
      document.body.classList.remove('fixed-nav')
    }
  }
  window.addEventListener('scroll', stickyNavigation, { passive: true })
}

// Show contextual notes
var post_meta_box = document.querySelector('.post-meta-box')

//if (post_meta_box) {
if (false) {
  let elements = document.querySelectorAll('.easy-footnote')

  //wrap the previous text node, marginAdjust, and nextFootnote in a span to avoid line breaks between the word and the footnote
  document
    .querySelectorAll('.easy-footnote-margin-adjust')
    .forEach(function (marginAdjust) {
      //console.log('applying marginAdjust fix ')
      // Find the previous sibling text node (the word before it)
      let previousTextNode = marginAdjust.previousSibling
      let previousTag = null
      let tagAttributes = null

      // Check if previous node is an element and store its info
      if (previousTextNode && previousTextNode.nodeType === Node.ELEMENT_NODE) {
        previousTag = previousTextNode.tagName
        tagAttributes = previousTextNode.attributes
        previousTextNode = previousTextNode.firstChild
      } else {
        while (
          previousTextNode &&
          previousTextNode.nodeType !== Node.TEXT_NODE
        ) {
          previousTextNode = previousTextNode.previousSibling
        }
      }

      // Find the next sibling .easy-footnote element
      let nextFootnote = marginAdjust.nextElementSibling
      while (
        nextFootnote &&
        !nextFootnote.classList.contains('easy-footnote')
      ) {
        nextFootnote = nextFootnote.nextElementSibling
      }

      if (previousTextNode && nextFootnote) {
        // Split the previous text node to isolate the last word
        //let textContent = previousTextNode.textContent.trim()
        let textContent = previousTextNode.textContent
        let lastSpaceIndex = textContent.lastIndexOf(' ')
        let lastWord = textContent.slice(lastSpaceIndex + 1)
        if (lastWord === '') {
          lastSpaceIndex = textContent.slice(0, lastSpaceIndex).lastIndexOf(' ')
          lastWord = textContent.slice(lastSpaceIndex + 1)
        }

        lastWord = textContent.slice(lastSpaceIndex + 1)
        let remainingText = textContent.slice(0, lastSpaceIndex + 1)

        // Update the previous text node with the remaining text
        if (previousTag) {
          // If word was in an element, create new element of same type
          let newElement = document.createElement(previousTag)
          // Copy over any attributes
          if (tagAttributes) {
            for (let i = 0; i < tagAttributes.length; i++) {
              newElement.setAttribute(
                tagAttributes[i].name,
                tagAttributes[i].value
              )
            }
          }
          newElement.textContent = lastWord
          previousTextNode.textContent = remainingText

          // Create wrapper and add elements
          let wrapper = document.createElement('span')
          wrapper.className = 'whitespace-nowrap'
          marginAdjust.parentNode.insertBefore(wrapper, marginAdjust)
          wrapper.appendChild(newElement)
          wrapper.appendChild(marginAdjust)
          wrapper.appendChild(nextFootnote)
        } else {
          // Original code for plain text
          previousTextNode.textContent = remainingText
          let lastWordNode = document.createTextNode(lastWord)
          let wrapper = document.createElement('span')
          wrapper.className = 'whitespace-nowrap'
          marginAdjust.parentNode.insertBefore(wrapper, marginAdjust)
          wrapper.appendChild(lastWordNode)
          wrapper.appendChild(marginAdjust)
          wrapper.appendChild(nextFootnote)
        }
      }
    })
  // Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;
  // let tootltip_w = post_meta_box.offsetWidth;
  // tippy.setDefaults({
  //   delay: [0, 500],
  //   size: "small",
  //   interactive: "true",
  //   boundary: "viewport",
  //   placement: "left-center",
  //   theme: "lgc",
  //   maxWidth: tootltip_w + "px",
  //   popperOptions: { gpuAcceleration: false }
  // });
  // for (let element of elements) {
  //   var el_padding = parseInt(
  //     window
  //       .getComputedStyle(element.parentElement, null)
  //       .getPropertyValue("padding-left")
  //   );
  //   console.log("el_padding: " + el_padding);
  //   var parent_offset =
  //     element.parentElement.getBoundingClientRect().left + el_padding;
  //   console.log("parent-offset= " + parent_offset);
  //   var tootltip_offset = document
  //     .querySelector(".post-meta-box")
  //     .getBoundingClientRect().left;
  //   var difference = parent_offset - tootltip_offset;
  //   console.log("meta-offset= " + tootltip_offset);
  //   console.log(difference);
  //   var x_offset =
  //     difference +
  //     element.offsetLeft -
  //     element.offsetWidth -
  //     el_padding -
  //     tootltip_w +
  //     5;
  //   console.log(x_offset);

  //   var desired_x = document
  //     .querySelector(".post-meta-box")
  //     .getBoundingClientRect().left;
  //   var ref_x = element.getBoundingClientRect().left;
  //   var x_offset = ref_x - desired_x;
  //   console.log("xxxxx_offset: " + x_offset);
  //   console.log("destination: " + desired_x + " position: " + ref_x);

  //   tippy(element, {
  //     offset: "0, " + -x_offset,
  //     content: element.querySelectorAll("a")[0].getAttribute("title")
  //   });
  // }
}

var timerHover
const popup = document.querySelector('#pop-up')
const x_pos = document.querySelector('#x_pos')
var popper = popup
var popperInstance

function notesHovers() {
  let elements = document.querySelectorAll('.easy-footnote')
  for (let element of elements) {
    elementHover(element)
  }

  function mouseenterHandler(event) {
    clearTimeout(timerHover)
    //if mobile or tablet, no action on hover
    if (x_pos.offsetParent === null) return

    let content = this.querySelectorAll('a')[0].getAttribute('title')
    popup.innerHTML =
      "<span class='easy-footnote block -ml-1 mb-1 '><a><sup>" +
      this.textContent +
      '</sup></a></span>' +
      content

    var reference = this

    var template_rect = x_pos.getBoundingClientRect()
    var x_offset = template_rect.left

    popup.style.width = template_rect.width + 'px'
    var popup_h = popup.getBoundingClientRect().height

    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = this.getBoundingClientRect(),
      offset
    var viewport_adjustment = 0
    if (
      elemRect.bottom + popup_h <=
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      offset = elemRect.top - bodyRect.top
      //console.log("In the viewport!");
    } else {
      viewport_adjustment =
        (window.innerHeight || document.documentElement.clientHeight) -
        (elemRect.bottom + popup_h)
      offset = elemRect.top - bodyRect.top + viewport_adjustment
      //console.log("Not in the viewport... whomp whomp");
    }

    popup.style.top = Math.round(offset) + 'px'
    popup.style.left = Math.round(x_offset) + 'px'
    popup.classList.add('active')

    sendGA(this)
  }

  function sendGA(elem) {
    let note = elem.querySelectorAll('a')[0]
    let noteID = note.getAttribute('href')
    noteID = noteID.replace('#easy-footnote-bottom-', '')

    let content = note.getAttribute('title') || note.getAttribute('data-title')
    content = content.slice(0, 12)
    console.log(noteID)
    window.dataLayer.push({
      event: 'ViewNote',
      note_id: noteID,
      note_title: content,
    })
  }

  function mouseleaveHandler() {
    timerHover = setTimeout(function () {
      popup.classList.remove('active') //popperInstance.destroy();
    }, 500)
  }

  popup.addEventListener('mouseenter', function () {
    clearTimeout(timerHover)
  })
  popup.addEventListener('mouseleave', mouseleaveHandler)

  const showDialog = () => {
    document.documentElement.style.overflow = 'hidden'
    var dialog = document.getElementById('modal_notes')
    dialog.style.display = 'block' // Make it visible
    document.getElementById('modal_content').style.display = 'block'
    var height = dialog.scrollHeight // +       document.getElementById("modal_content").scrollHeight; // Get it's height
    dialog.classList.add('show')
  }
  const closeDialog = () => {
    document.documentElement.style.overflow = ''
    document.getElementById('modal_notes').classList.remove('show')
    document.getElementById('modal_notes').style.display = 'none'
  }
  if (document.getElementById('modal_close')) {
    document
      .getElementById('modal_close')
      .addEventListener('click', function (e) {
        //document.body.classList.remove("modal_open");
        closeDialog()
      })
  }

  function elementHover(element) {
    element.addEventListener('mouseenter', mouseenterHandler)
    element.addEventListener('mouseleave', mouseleaveHandler)
    element.querySelector('a').addEventListener('click', function (e) {
      e.preventDefault()
      if (x_pos.offsetParent === null) {
        showDialog()
        let content =
          element.querySelectorAll('a')[0].getAttribute('title') ||
          element.querySelectorAll('a')[0].getAttribute('data-title')
        document.getElementById('modal_text').innerHTML =
          "<span class='easy-footnote block -ml-1 mb-1 '><a><sup>" +
          this.textContent +
          '</sup></a></span>' +
          content

        sendGA(element)
      } else {
        var target = this.getAttribute('href')
        target = document.querySelector(target)
        target = target.parentElement.offsetTop - 80
        window.scrollTo({
          top: target,
          left: 0,
          behavior: 'smooth',
        })
      }
    })
    element.querySelector('a').addEventListener('mouseenter', function () {
      this.setAttribute('data-title', this.title)
      this.title = ''
    })
    element.querySelector('a').addEventListener('mouseleave', function () {
      this.setAttribute('title', this.getAttribute('data-title'))
    })
  }
}
if (popup) {
  notesHovers()
}

// Archive page

function filtersMenu() {
  const filters = document.querySelector('.js-filters-nav')
  const filtersToggle = document.querySelector('.js-filters-toggle')
  const openFilters = document.querySelector('.js-open-filters')
  const closeFilters = document.querySelector('.js-close-filters')

  if (filtersToggle) {
    filtersToggle.addEventListener('click', (e) => {
      e.preventDefault()
      e.stopImmediatePropagation()
      filtersToggle.classList.toggle('active')
      filters.classList.toggle('hidden')
      openFilters.classList.toggle('hidden')
      closeFilters.classList.toggle('hidden')
    })
  }
}

filtersMenu()

// archive carousels
var archive_carousel = document.querySelector('.archive-carousel')
if (archive_carousel) {
  var flkty_archive = new Flickity(archive_carousel, {
    prevNextButtons: false,
    pageDots: false,
    adaptiveHeight: false,
    cellAlign: 'left',
    contain: false,
    watchCSS: true,
  })
}

// Reference the parent of all .accordion
var main = document.getElementsByClassName('main')[0]
if (main) {
  /* Register main to click events...
  || when main or any of its descendant elements are clicked...
  */
  main.addEventListener('click', function (e) {
    /* Collect all .accordion into a NodeList and convert it into
    || an array.
    */
    var acc = Array.from(document.querySelectorAll('.wp-block-lgc-asection h2'))

    /* Loop thru each .accordion  to remove the .active class
    || from each .panel
    */
    // for (let a = 0; a < acc.length; a++) {
    //   var panel = acc[a].nextElementSibling;
    //   panel.classList.remove('active');
    // }
    /* After nothing has class .active, assign .active to the
    || .panel of the clicked element (e.target)
    */
    if (e.target !== e.currentTarget) {
      // var tgt = e.target.nextElementSibling;
      var tgt = e.target.parentElement
      tgt.classList.toggle('active')
    }
  })
}

/*  HOVER EFFECT  */

// var trigger_hovers = document.getElementsByClassName("js-hover");
// Array.prototype.forEach.call(trigger_hovers, function(element, index, array) {
//   //console.log(index);
//   element.c_parent = element.closest(".hover-element");
//   element.addEventListener('mouseenter', function(){ this.c_parent.classList.add('hover'); } );
//   element.addEventListener('mouseleave', function(){ this.c_parent.classList.remove('hover'); } )

//   console.log(element.custom_parent);
// });

/*  OBSERVATOIRE  */

var tab_slider = document.querySelector('.tab-slider')
var tabs = document.getElementsByClassName('tab-slide')

if (tab_slider) {
  for (var i = 0; i < tabs.length; i++) {
    tabs[i]
      .querySelector('.main-iframe')
      .setAttribute(
        'data-src',
        tabs[i].querySelector('.main-iframe').getAttribute('src')
      )
    tabs[i]
      .querySelector('.second-iframe')
      .setAttribute(
        'data-src',
        tabs[i].querySelector('.second-iframe').getAttribute('src')
      )

    // tabs[i].querySelector(".main-iframe").setAttribute('src', '');
    // tabs[i].querySelector(".second-iframe").setAttribute('src', '');
  }

  var flkty_observatoire
  flkty_observatoire = new Flickity(tab_slider, {
    // options
    wrapAround: false,
    groupCells: false,
    imagesLoaded: false,
    prevNextButtons: true,
    pageDots: false,
    draggable: false,
    cellAlign: 'left',
    adaptiveHeight: true,

    //contain: false,
    watchCSS: true,
    // selectedAttraction: 0.2,
    // friction: 0.8,
    //    fade: true
    on: {
      ready: function () {
        // console.log('Flickity is ready');
        var current_tab = tabs[0]
        var main = current_tab.querySelector('.main-iframe')
        var second = current_tab.querySelector('.second-iframe')
        main.setAttribute('src', main.getAttribute('data-src'))
        main.classList.add('show')
        second.setAttribute('src', second.getAttribute('data-src'))
        second.classList.add('show')
      },
      change: function (index) {
        //console.log(index);
        for (var i = 0; i < tabs.length; i++) {
          tabs[i].querySelector('.main-iframe').setAttribute('src', '')
          tabs[i].querySelector('.second-iframe').setAttribute('src', '')
          tabs[i].querySelector('.main-iframe').classList.remove('show')
          tabs[i].querySelector('.second-iframe').classList.remove('show')
        }
        for (var i = 0; i < tab_btns.length; i++) {
          tab_btns[i].classList.remove('active')
        }
        tab_btns[index].classList.add('active')

        var current_tab = tabs[index]
        var main = current_tab.querySelector('.main-iframe')
        var second = current_tab.querySelector('.second-iframe')
        main.setAttribute('src', main.getAttribute('data-src'))
        main.classList.add('show')
        second.setAttribute('src', second.getAttribute('data-src'))
        second.classList.add('show')
      },
    },
  })

  setInterval(function () {
    flkty_observatoire.resize()
  }, 1000)

  var data_carousel_container = document.querySelector('.data-block-carousel')
  if (data_carousel_container) {
    var data_carousel = new Flickity(data_carousel_container, {
      // options
      prevNextButtons: false,
      pageDots: false,
      cellAlign: 'left',
      contain: true,
      watchCSS: true,
    })
  }

  var tab_btns = document.getElementsByClassName('tab-btn')
  Array.prototype.forEach.call(tab_btns, function (element, index, array) {
    element.addEventListener('click', function (e) {
      var slide = this.getAttribute('data-slide')
      //flkty_observatoire.select( slide );
      flkty_observatoire.select(slide, false, true)
      for (var i = 0; i < tab_btns.length; i++) {
        tab_btns[i].classList.remove('active')
      }
      this.classList.add('active')
    })
  })

  var scroll_to_btn = document.querySelector('.js-scroll-to')
  if (scroll_to_btn) {
    scroll_to_btn.addEventListener('click', function (e) {
      e.preventDefault()
      var target = this.getAttribute('href')
      target = document.querySelector(target)
      target = target.offsetTop - 80
      window.scrollTo({
        top: target,
        left: 0,
        behavior: 'smooth',
      })
    })
  }
}

/*  OBSERVATOIRE V2  */

var obs_v2 = document.querySelector('.page-template-page-newsroom-v2')

if (obs_v2) {
  var data_carousel_container = document.querySelector('.data-block-carousel')
  if (data_carousel_container) {
    var data_carousel = new Flickity(data_carousel_container, {
      // options
      prevNextButtons: false,
      pageDots: false,
      cellAlign: 'left',
      contain: true,
      watchCSS: true,
    })
  }

  document
    .querySelector('.js-obs-toggle')
    .addEventListener('click', function () {
      document.getElementById('obs-about').classList.toggle('expanded')
    })
}

// about languages dropdown
let lang_menu = document.getElementById('lang_toggle')
if (lang_menu) {
  lang_menu.addEventListener('click', (e) => {
    document.body.classList.toggle('show_lang_menu')
  })
}

// PDF newsletter
let pdf_btn = document.getElementById('expand-pdf-box')
if (pdf_btn) {
  pdf_btn.addEventListener('click', (e) => {
    document.body.classList.toggle('show_pdf_box')
  })
}

//Add to cart btn Google TAG (.subscribe_btn)
let subscribe_btns = document.getElementsByClassName('add_to_cart_sub_btn')
Array.prototype.forEach.call(subscribe_btns, function (element, index, array) {
  element.addEventListener('click', function (e) {
    //e.preventDefault()
    console.log(
      this.getAttribute('data-ga-price'),
      this.getAttribute('data-ga-sku'),
      this.getAttribute('data-ga-name')
    )
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'EUR',
        value: this.getAttribute('data-ga-price'),
        items: [
          {
            item_id: this.getAttribute('data-ga-sku'),
            item_name: this.getAttribute('data-ga-name'),
            price: this.getAttribute('data-ga-price'),
            quantity: 1,
          },
        ],
      },
    })
  })
})

// about carousels
var stats_carousel = document.querySelector('.stats-carousel')
if (stats_carousel) {
  var statsSlider = new Flickity(stats_carousel, {
    prevNextButtons: false,
    pageDots: false,
    adaptiveHeight: false,
    cellAlign: 'left',
    contain: false,
    watchCSS: true,
  })
}

var galleryElems = document.querySelectorAll('.iphones-carousel')

for (var i = 0, len = galleryElems.length; i < len; i++) {
  var galleryElem = galleryElems[i]
  new Flickity(galleryElem, {
    prevNextButtons: false,
    pageDots: false,
    //adaptiveHeight: false,
    cellAlign: 'left',
    contain: false,
    watchCSS: true,
    draggable: '>1',
    dragThreshold: 0,
    freeScroll: true,
    //autoPlay: 1500,
    //wrapAround: true,
  })
}

function scrollSpy() {
  const makeNavLinksSmooth = () => {
    const navLinks = document.querySelectorAll('.js-scroll')
    for (let n in navLinks) {
      if (navLinks.hasOwnProperty(n)) {
        navLinks[n].addEventListener('click', (e) => {
          e.preventDefault()
          document.querySelector(navLinks[n].hash).scrollIntoView({
            behavior: 'smooth',
          })
          return false
        })
      }
    }
  }

  const spyScrolling = () => {
    const sections = document.querySelectorAll('section .anchor-section')
    window.onscroll = () => {
      const scrollPos =
        document.documentElement.scrollTop || document.body.scrollTop
      for (let s in sections)
        if (sections.hasOwnProperty(s) && sections[s].offsetTop <= scrollPos) {
          const id = sections[s].id
          document.querySelector('.active').classList.remove('active')
          document.querySelector(`a[href*=${id}]`).classList.add('active')
        }
    }
  }
  makeNavLinksSmooth()
  spyScrolling()
}

function throttle(func, wait = 100) {
  let timer = null
  return function (...args) {
    if (timer === null) {
      timer = setTimeout(() => {
        func.apply(this, args)
        timer = null
      }, wait)
    }
  }
}

const throttled = throttle(scrollSpy, 300)
window.addEventListener('resize', throttled)

function getBreakPoint() {
  if (document.querySelector('#main-container')) {
    return window
      .getComputedStyle(document.querySelector('#main-container'), ':before')
      .getPropertyValue('content')
  }
}
let breakpoint = getBreakPoint()
window.addEventListener('resize', function () {
  var newBreakPoint = getBreakPoint()
  if (breakpoint != newBreakPoint) {
    //location.reload();
    document.body.classList.add('opacity-25')
    window.location = window.location
  }
  breakpoint = newBreakPoint
})
//document.body.addEventListener('click', throttled);
throttled()

var easing = {
  quadratic: function (x) {
    return Math.sqrt(x)
  },
}

function range(start, stop, step) {
  var array = []
  for (var i = start; i < stop; i += step) array.push(i)
  return array
}

function interpolation(fps, easing, finalValue) {
  function scaleIt(value) {
    return finalValue * value
  }

  var x = range(0, 1, 1 / fps),
    y = x.map(easing).map(scaleIt)

  return y
}

function animateEl(values, duration, onAnimate) {
  var frameIndex = 0,
    fps = values.length,
    id = setInterval(anime, duration / fps)

  function anime() {
    var current = values[frameIndex],
      isLastFrame = frameIndex === fps - 1

    onAnimate(current, frameIndex, values)

    if (isLastFrame) {
      clearInterval(id)
    } else {
      frameIndex++
    }
  }
}

function round(value, decimals) {
  return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals)
}

function unformat(content) {
  var unlocalized = content.replace('.', '').replace(',', '.'),
    value = parseFloat(unlocalized)
  return value
}

function format(value) {
  return value.toString().replace('.', ',')
}

function increaseNum() {
  var fps = 30,
    els = [].slice.call(document.querySelectorAll('.slowNumber'))

  els.forEach(function (el) {
    var content = el.firstChild.textContent.trim(),
      decimalPlaces = content.split(',')[1] || '',
      value = unformat(content),
      values = interpolation(fps, easing.quadratic, value)

    animateEl(values, 1000, function (current, i, values) {
      var isLast = i === values.length - 1,
        value = round(current, decimalPlaces.length)
      el.firstChild.textContent = isLast ? content : format(value)
    })
  })
}
